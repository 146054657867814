<template>
  <section class="section main-section">
    <h4 class="section__title section__title_centered mb-1">
      Генерация отчета по уже истекщим (неактивным) подпискам
    </h4>
    <GoBackIcon back-route-name="main.reports" />
    <div class="q-my-md q-mx-auto" style="width: 250px">
      <q-select
        filled
        v-model="selectedDgd"
        :options="dgdList"
        label="Выберите ДГД"
        clearable
      />
    </div>
    <GenerateReportBlock
      report-name="Истекщие (нетакивные) подписки"
      :is-generating-report="isGeneratingReport"
      @generateReport="generateReport"
    />
  </section>
</template>

<script>
import { defineComponent, ref, onBeforeMount } from "vue";
import GoBackIcon from "@/components/icon-components/GoBackIcon.vue";
import GenerateReportBlock from "@/views/Reports/components/GenerateReportBlock.vue";
import reportsApi from "@/api/reports.api";
import {
  formatBlobErrorToMsg,
  objectPropertiesToQuery,
} from "@/helpers/utils/format.utils";
import { downloadBlob } from "@/helpers/utils/download.utils";
import { dgdApi } from "@/api/dgd.api";
import slugify from "slugify";

export default defineComponent({
  name: "AlreadyExpiredSubsReportPage",
  components: {
    GoBackIcon,
    GenerateReportBlock,
  },

  setup() {
    const selectedDgd = ref(null);
    const dgdList = ref([]);
    const isGeneratingReport = ref(false);
    const generateReport = async (dates) => {
      isGeneratingReport.value = true;
      try {
        const queryObject = { ...dates };
        if (selectedDgd.value) queryObject.dgd_id = selectedDgd.value.id;
        const query = objectPropertiesToQuery(queryObject);
        const response = await reportsApi.getExpiredSubsReport(query);
        let fileName = `wipon-inactive-subs_${dates.from}-${dates.to}`;

        if (selectedDgd.value) {
          const dgdSluggedName = slugify(selectedDgd.value.name_ru, {
            lower: true,
          }).split("-")[2];
          fileName = `${dgdSluggedName}-oblasti-${fileName}`;
        }

        downloadBlob(response, fileName);
      } catch (e) {
        await formatBlobErrorToMsg(e);
      } finally {
        isGeneratingReport.value = false;
      }
    };

    const fetchDgdList = async () => {
      const { data } = await dgdApi.fetchDgds();
      if (data && Array.isArray(data)) {
        data.forEach((el) => {
          el.label = el.name_ru;
        });
        dgdList.value = data;
      }
    };

    onBeforeMount(async () => {
      await fetchDgdList();
    });

    return {
      selectedDgd,
      dgdList,
      isGeneratingReport,
      generateReport,
    };
  },
});
</script>
